/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addTagToImage } from '../fn/image-controller/add-tag-to-image';
import { AddTagToImage$Params } from '../fn/image-controller/add-tag-to-image';
import { createImageShareLink } from '../fn/image-controller/create-image-share-link';
import { CreateImageShareLink$Params } from '../fn/image-controller/create-image-share-link';
import { deleteImageShareLink } from '../fn/image-controller/delete-image-share-link';
import { DeleteImageShareLink$Params } from '../fn/image-controller/delete-image-share-link';
import { getImage } from '../fn/image-controller/get-image';
import { GetImage$Params } from '../fn/image-controller/get-image';
import { getImageByShareToken } from '../fn/image-controller/get-image-by-share-token';
import { GetImageByShareToken$Params } from '../fn/image-controller/get-image-by-share-token';
import { getImages } from '../fn/image-controller/get-images';
import { GetImages$Params } from '../fn/image-controller/get-images';
import { getImageShareLink } from '../fn/image-controller/get-image-share-link';
import { GetImageShareLink$Params } from '../fn/image-controller/get-image-share-link';
import { ImageDto } from '../models/image-dto';
import { ImageValidationDto } from '../models/image-validation-dto';
import { PageImageDto } from '../models/page-image-dto';
import { processImageInQueue } from '../fn/image-controller/process-image-in-queue';
import { ProcessImageInQueue$Params } from '../fn/image-controller/process-image-in-queue';
import { removeTagFromImage } from '../fn/image-controller/remove-tag-from-image';
import { RemoveTagFromImage$Params } from '../fn/image-controller/remove-tag-from-image';
import { validateImage } from '../fn/image-controller/validate-image';
import { ValidateImage$Params } from '../fn/image-controller/validate-image';

@Injectable({ providedIn: 'root' })
export class ImageControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getImages()` */
  static readonly GetImagesPath = '/images';

  /**
   * Get all images.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages$Response(params?: GetImages$Params, context?: HttpContext): Observable<StrictHttpResponse<PageImageDto>> {
    return getImages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all images.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImages(params?: GetImages$Params, context?: HttpContext): Observable<PageImageDto> {
    return this.getImages$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageImageDto>): PageImageDto => r.body)
    );
  }

  /** Path part for operation `processImageInQueue()` */
  static readonly ProcessImageInQueuePath = '/images';

  /**
   * Process image in queue.
   *
   * Allowed formats: jpeg, jpg, png, webp, gif, tiff, bmp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processImageInQueue()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  processImageInQueue$Response(params?: ProcessImageInQueue$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return processImageInQueue(this.http, this.rootUrl, params, context);
  }

  /**
   * Process image in queue.
   *
   * Allowed formats: jpeg, jpg, png, webp, gif, tiff, bmp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processImageInQueue$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  processImageInQueue(params?: ProcessImageInQueue$Params, context?: HttpContext): Observable<{
}> {
    return this.processImageInQueue$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `addTagToImage()` */
  static readonly AddTagToImagePath = '/images/{id}/tags/{tagId}';

  /**
   * Add tag to image.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTagToImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTagToImage$Response(params: AddTagToImage$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return addTagToImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Add tag to image.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTagToImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTagToImage(params: AddTagToImage$Params, context?: HttpContext): Observable<{
}> {
    return this.addTagToImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `removeTagFromImage()` */
  static readonly RemoveTagFromImagePath = '/images/{id}/tags/{tagId}';

  /**
   * Remove tag from image.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTagFromImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTagFromImage$Response(params: RemoveTagFromImage$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return removeTagFromImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove tag from image.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeTagFromImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTagFromImage(params: RemoveTagFromImage$Params, context?: HttpContext): Observable<{
}> {
    return this.removeTagFromImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getImageShareLink()` */
  static readonly GetImageShareLinkPath = '/images/{id}/share';

  /**
   * Get share link for image.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageShareLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageShareLink$Response(params: GetImageShareLink$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getImageShareLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Get share link for image.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImageShareLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageShareLink(params: GetImageShareLink$Params, context?: HttpContext): Observable<string> {
    return this.getImageShareLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `createImageShareLink()` */
  static readonly CreateImageShareLinkPath = '/images/{id}/share';

  /**
   * Create share link for image.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createImageShareLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  createImageShareLink$Response(params: CreateImageShareLink$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createImageShareLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Create share link for image.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createImageShareLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createImageShareLink(params: CreateImageShareLink$Params, context?: HttpContext): Observable<string> {
    return this.createImageShareLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteImageShareLink()` */
  static readonly DeleteImageShareLinkPath = '/images/{id}/share';

  /**
   * Delete share link for image.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteImageShareLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageShareLink$Response(params: DeleteImageShareLink$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return deleteImageShareLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete share link for image.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteImageShareLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageShareLink(params: DeleteImageShareLink$Params, context?: HttpContext): Observable<{
}> {
    return this.deleteImageShareLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `validateImage()` */
  static readonly ValidateImagePath = '/images/validate';

  /**
   * Validate image.
   *
   * Allowed formats: jpeg, jpg, png, webp, gif, tiff, bmp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateImage$Response(params?: ValidateImage$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageValidationDto>> {
    return validateImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Validate image.
   *
   * Allowed formats: jpeg, jpg, png, webp, gif, tiff, bmp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateImage(params?: ValidateImage$Params, context?: HttpContext): Observable<ImageValidationDto> {
    return this.validateImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageValidationDto>): ImageValidationDto => r.body)
    );
  }

  /** Path part for operation `getImage()` */
  static readonly GetImagePath = '/images/{id}';

  /**
   * Get image by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImage$Response(params: GetImage$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageDto>> {
    return getImage(this.http, this.rootUrl, params, context);
  }

  /**
   * Get image by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImage(params: GetImage$Params, context?: HttpContext): Observable<ImageDto> {
    return this.getImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageDto>): ImageDto => r.body)
    );
  }

  /** Path part for operation `getImageByShareToken()` */
  static readonly GetImageByShareTokenPath = '/images/shared/{shareToken}';

  /**
   * Get image by shared link.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageByShareToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageByShareToken$Response(params: GetImageByShareToken$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageDto>> {
    return getImageByShareToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Get image by shared link.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImageByShareToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageByShareToken(params: GetImageByShareToken$Params, context?: HttpContext): Observable<ImageDto> {
    return this.getImageByShareToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageDto>): ImageDto => r.body)
    );
  }

}
