/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTag } from '../fn/tag-controller/create-tag';
import { CreateTag$Params } from '../fn/tag-controller/create-tag';
import { deleteTag } from '../fn/tag-controller/delete-tag';
import { DeleteTag$Params } from '../fn/tag-controller/delete-tag';
import { getTags } from '../fn/tag-controller/get-tags';
import { GetTags$Params } from '../fn/tag-controller/get-tags';
import { TagDto } from '../models/tag-dto';

@Injectable({ providedIn: 'root' })
export class TagControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTags()` */
  static readonly GetTagsPath = '/tags';

  /**
   * Get all tags.
   *
   * Get all tags within the users organization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTags$Response(params?: GetTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagDto>>> {
    return getTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all tags.
   *
   * Get all tags within the users organization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTags(params?: GetTags$Params, context?: HttpContext): Observable<Array<TagDto>> {
    return this.getTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagDto>>): Array<TagDto> => r.body)
    );
  }

  /** Path part for operation `createTag()` */
  static readonly CreateTagPath = '/tags';

  /**
   * Create a new tag.
   *
   * Create a new tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag$Response(params: CreateTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagDto>> {
    return createTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new tag.
   *
   * Create a new tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag(params: CreateTag$Params, context?: HttpContext): Observable<TagDto> {
    return this.createTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagDto>): TagDto => r.body)
    );
  }

  /** Path part for operation `deleteTag()` */
  static readonly DeleteTagPath = '/tags/{id}';

  /**
   * Delete a tag.
   *
   * Delete a tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTag$Response(params: DeleteTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagDto>> {
    return deleteTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a tag.
   *
   * Delete a tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTag(params: DeleteTag$Params, context?: HttpContext): Observable<TagDto> {
    return this.deleteTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagDto>): TagDto => r.body)
    );
  }

}
