/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acceptInvitation } from '../fn/organization-controller/accept-invitation';
import { AcceptInvitation$Params } from '../fn/organization-controller/accept-invitation';
import { createInvitation } from '../fn/organization-controller/create-invitation';
import { CreateInvitation$Params } from '../fn/organization-controller/create-invitation';
import { deleteOrganization } from '../fn/organization-controller/delete-organization';
import { DeleteOrganization$Params } from '../fn/organization-controller/delete-organization';
import { deleteUser1 } from '../fn/organization-controller/delete-user-1';
import { DeleteUser1$Params } from '../fn/organization-controller/delete-user-1';
import { demoteUser } from '../fn/organization-controller/demote-user';
import { DemoteUser$Params } from '../fn/organization-controller/demote-user';
import { getOrganization } from '../fn/organization-controller/get-organization';
import { GetOrganization$Params } from '../fn/organization-controller/get-organization';
import { getUsersInOrganization } from '../fn/organization-controller/get-users-in-organization';
import { GetUsersInOrganization$Params } from '../fn/organization-controller/get-users-in-organization';
import { OrganizationDto } from '../models/organization-dto';
import { promoteUser } from '../fn/organization-controller/promote-user';
import { PromoteUser$Params } from '../fn/organization-controller/promote-user';
import { updateOrganization } from '../fn/organization-controller/update-organization';
import { UpdateOrganization$Params } from '../fn/organization-controller/update-organization';
import { UserDto } from '../models/user-dto';

@Injectable({ providedIn: 'root' })
export class OrganizationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrganization()` */
  static readonly GetOrganizationPath = '/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Response(params?: GetOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationDto>> {
    return getOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization(params?: GetOrganization$Params, context?: HttpContext): Observable<OrganizationDto> {
    return this.getOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

  /** Path part for operation `updateOrganization()` */
  static readonly UpdateOrganizationPath = '/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrganization$Response(params: UpdateOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationDto>> {
    return updateOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrganization(params: UpdateOrganization$Params, context?: HttpContext): Observable<OrganizationDto> {
    return this.updateOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

  /** Path part for operation `deleteOrganization()` */
  static readonly DeleteOrganizationPath = '/organizations';

  /**
   * Delete the organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganization$Response(params?: DeleteOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return deleteOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete the organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganization(params?: DeleteOrganization$Params, context?: HttpContext): Observable<{
}> {
    return this.deleteOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `promoteUser()` */
  static readonly PromoteUserPath = '/organizations/promoteUserToAdmin/{userId}';

  /**
   * Give a user in the organization the admin role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promoteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  promoteUser$Response(params: PromoteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return promoteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Give a user in the organization the admin role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promoteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promoteUser(params: PromoteUser$Params, context?: HttpContext): Observable<{
}> {
    return this.promoteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `demoteUser()` */
  static readonly DemoteUserPath = '/organizations/demoteUserFromAdmin/{userId}';

  /**
   * Remove the admin role from a user in the organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demoteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoteUser$Response(params: DemoteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return demoteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove the admin role from a user in the organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demoteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoteUser(params: DemoteUser$Params, context?: HttpContext): Observable<{
}> {
    return this.demoteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `createInvitation()` */
  static readonly CreateInvitationPath = '/organizations/createInvitation';

  /**
   * Create an invitation for a new user to join the organization. The new user will be notified via email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  createInvitation$Response(params: CreateInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return createInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an invitation for a new user to join the organization. The new user will be notified via email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createInvitation(params: CreateInvitation$Params, context?: HttpContext): Observable<{
}> {
    return this.createInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `acceptInvitation()` */
  static readonly AcceptInvitationPath = '/organizations/acceptInvitation';

  /**
   * Accept an invitation to join an organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptInvitation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptInvitation$Response(params: AcceptInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return acceptInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * Accept an invitation to join an organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptInvitation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptInvitation(params: AcceptInvitation$Params, context?: HttpContext): Observable<{
}> {
    return this.acceptInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getUsersInOrganization()` */
  static readonly GetUsersInOrganizationPath = '/organizations/users';

  /**
   * Get all users in an organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersInOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersInOrganization$Response(params?: GetUsersInOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserDto>>> {
    return getUsersInOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all users in an organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersInOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersInOrganization(params?: GetUsersInOrganization$Params, context?: HttpContext): Observable<Array<UserDto>> {
    return this.getUsersInOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>): Array<UserDto> => r.body)
    );
  }

  /** Path part for operation `deleteUser1()` */
  static readonly DeleteUser1Path = '/organizations/users/{userId}';

  /**
   * Deletes a user in an organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser1$Response(params: DeleteUser1$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return deleteUser1(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes a user in an organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser1(params: DeleteUser1$Params, context?: HttpContext): Observable<{
}> {
    return this.deleteUser1$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
