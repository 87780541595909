/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserDto } from '../../models/user-dto';

export interface AcceptInvitation$Params {

/**
 * The code of the invitation to accept
 */
  code: string;

/**
 * The user that will be created and added to the organization
 */
  user: any;
      body: UserDto
}

export function acceptInvitation(http: HttpClient, rootUrl: string, params: AcceptInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, acceptInvitation.PATH, 'post');
  if (params) {
    rb.query('code', params.code, {});
    rb.query('user', params.user, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

acceptInvitation.PATH = '/organizations/acceptInvitation';
