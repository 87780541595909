/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changeLicenseQuantity } from '../fn/stripe-controller/change-license-quantity';
import { ChangeLicenseQuantity$Params } from '../fn/stripe-controller/change-license-quantity';
import { createOneTimePurchase } from '../fn/stripe-controller/create-one-time-purchase';
import { CreateOneTimePurchase$Params } from '../fn/stripe-controller/create-one-time-purchase';
import { createSubscription } from '../fn/stripe-controller/create-subscription';
import { CreateSubscription$Params } from '../fn/stripe-controller/create-subscription';
import { manageSubscription } from '../fn/stripe-controller/manage-subscription';
import { ManageSubscription$Params } from '../fn/stripe-controller/manage-subscription';
import { StripeLinkDto } from '../models/stripe-link-dto';

@Injectable({ providedIn: 'root' })
export class StripeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `manageSubscription()` */
  static readonly ManageSubscriptionPath = '/stripe/manageSubscription';

  /**
   * [REDIRECT] Manage the current subscription within the Stripe customer portal.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manageSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  manageSubscription$Response(params?: ManageSubscription$Params, context?: HttpContext): Observable<StrictHttpResponse<StripeLinkDto>> {
    return manageSubscription(this.http, this.rootUrl, params, context);
  }

  /**
   * [REDIRECT] Manage the current subscription within the Stripe customer portal.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manageSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  manageSubscription(params?: ManageSubscription$Params, context?: HttpContext): Observable<StripeLinkDto> {
    return this.manageSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<StripeLinkDto>): StripeLinkDto => r.body)
    );
  }

  /** Path part for operation `createSubscription()` */
  static readonly CreateSubscriptionPath = '/stripe/createSubscription/{planId}/{quantity}';

  /**
   * [REDIRECT] Create initial subscription buying process.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSubscription$Response(params: CreateSubscription$Params, context?: HttpContext): Observable<StrictHttpResponse<StripeLinkDto>> {
    return createSubscription(this.http, this.rootUrl, params, context);
  }

  /**
   * [REDIRECT] Create initial subscription buying process.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSubscription(params: CreateSubscription$Params, context?: HttpContext): Observable<StripeLinkDto> {
    return this.createSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<StripeLinkDto>): StripeLinkDto => r.body)
    );
  }

  /** Path part for operation `createOneTimePurchase()` */
  static readonly CreateOneTimePurchasePath = '/stripe/createOneTimePurchase/{planId}/{quantity}';

  /**
   * [REDIRECT] Create one-time purchase buying process.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOneTimePurchase()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOneTimePurchase$Response(params: CreateOneTimePurchase$Params, context?: HttpContext): Observable<StrictHttpResponse<StripeLinkDto>> {
    return createOneTimePurchase(this.http, this.rootUrl, params, context);
  }

  /**
   * [REDIRECT] Create one-time purchase buying process.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOneTimePurchase$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createOneTimePurchase(params: CreateOneTimePurchase$Params, context?: HttpContext): Observable<StripeLinkDto> {
    return this.createOneTimePurchase$Response(params, context).pipe(
      map((r: StrictHttpResponse<StripeLinkDto>): StripeLinkDto => r.body)
    );
  }

  /** Path part for operation `changeLicenseQuantity()` */
  static readonly ChangeLicenseQuantityPath = '/stripe/changeLicenseQuantity/{newQuantity}';

  /**
   * [REDIRECT] Increase the amount of licenses of the current subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeLicenseQuantity()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeLicenseQuantity$Response(params: ChangeLicenseQuantity$Params, context?: HttpContext): Observable<StrictHttpResponse<StripeLinkDto>> {
    return changeLicenseQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * [REDIRECT] Increase the amount of licenses of the current subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeLicenseQuantity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeLicenseQuantity(params: ChangeLicenseQuantity$Params, context?: HttpContext): Observable<StripeLinkDto> {
    return this.changeLicenseQuantity$Response(params, context).pipe(
      map((r: StrictHttpResponse<StripeLinkDto>): StripeLinkDto => r.body)
    );
  }

}
